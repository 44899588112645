.card {
  min-width: 10em;
  max-width: 16em;
  width: 80%;

  margin: 0.5em;

  border: 0.1em solid black;
  border-radius: 25px;
  box-shadow: 0.2em 0.2em;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  margin: 0;
  width: 80%;
  text-transform: capitalize;

  overflow: auto;
  word-wrap: break-word;
}

.bottom-row {
  width: 80%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.text {
  overflow: auto;
  word-wrap: break-word;
}

.small-text {
  font-size: small;
  overflow: auto;
  word-wrap: break-word;

  padding-bottom: 1em;
}

.media {
  text-align: center;
}

.image {
  object-fit: cover;
  width: 90%;

  border-radius: 5px;
}

.username {
  color: #5a7fa8;
}
