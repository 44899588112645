.body {
  display: flex;
  justify-content: center;
}

.container {
  width: 80%;

  display: flex;
  flex-direction: row;
  
  align-items: flex-start;
  align-content: center;
  flex-wrap: nowrap;
}

.post-form {
  text-align: center;
  width: 100%;

  margin-bottom: 1em;
}

.form-entry {
  padding-bottom: 1em;
}

.form-label {
  display: inline-block;
  width: 25%;
  text-align: left;
}

.caveat {
  padding-top: 0;
  padding-bottom: 0.5em;
  padding-right: 10%;
  text-align: right;
}

.form-btn {
  background-color: white;
  width: 70%;
  height: 2em;
  font-size: large;

  border: 0.15em solid black;
  border-radius: 10px;
}

.form-btn:hover, .form-btn:focus {
  cursor: pointer;
  background-color: #b8b8b8;
}

.form-col {
  display: flex;
  flex-direction: column;

  align-items: center;
  align-content: center;

  width: 30%;
  min-width: 175px;

  border: 0.1em solid black;
  border-radius: 25px;
  box-shadow: 0.2em 0.2em;
}

.row {
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .container {
    flex-direction: column;
  
    align-items: center;
    align-content: center;
  }

  .form-col {
    width: 80%;
  }
}
